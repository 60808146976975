import React, { Component } from 'react'
import styles from './contactform.module.scss'
import SnackbarWrapper from '../SnackBarWrapper/SnackBarWrapper';
import Fade from 'react-reveal/Fade';

class ContactForm extends Component {

  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    success: false,
    error: false
  }

   handleSubmit(e) {
    e.preventDefault()
    const {name, email, subject, message } = this.state
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("message", message);

    const url = `https://iqube-sendgrid.herokuapp.com/contact`
      fetch(url, {
        method: 'POST',
        body: formData,
      })
      .then((res) => {
        console.log(res.ok)
        if (res.ok) {
          this.setState({
            success: true
          }) 
        } else if (!res.ok) {
          this.setState({
            error: true
          })
        } 
      })
      .catch(err => console.log(err));
   }

   handleChange = (e) => {
     this.setState({
       [e.target.name]: e.target.value
     })
   }

   clearMessage = () => {
     this.setState({
      success: false,
      error: false,
      name: '',
      email: '',
      subject: '',
      message: '',
     })
   }

  render() {
    let message 
    if (this.state.error) {
      message = <SnackbarWrapper variant="error" message="Error Submitting Message. Please try again :)" onClose={this.clearMessage} />
    } 
     if (this.state.success) {
      message = <SnackbarWrapper variant="info" message="Message submitted Successfully" onClose={this.clearMessage} />
    } 
    return (
      <Fade>
        <form className={styles.form} method="POST" onSubmit={event => this.handleSubmit(event)}>
          <input ref="form-name"  type="hidden" name="form-name" value="Contact Form" />
          {message}
          <label> 
            Name
          </label>
            <input type="text"
              name="name"
              placeholder="Elon Musk"
              required
              onChange={this.handleChange} value={this.state.name}
            />
          <label>
            Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="elonthedon@mars.com"
              required
              onChange={this.handleChange} value={this.state.email}
            />
            <label>
              Subject
            </label>
            <input
              type="text"
              name="subject"
              placeholder="elonthedon@mars.com"
              required
              onChange={this.handleChange} value={this.state.subject}
            />
          <label>
            Message
            </label>
            <textarea
              type="text"
              name="message"
              placeholder = "We would like you to build the next space ship for us"
              required
              ref="message"
              onChange={this.handleChange} value={this.state.message}
            />
            <input className={styles.button} type="submit" />
        </form>
      </Fade>
    )
  }
}

export default ContactForm

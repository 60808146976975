import React from "react"
import Layout from "../components/layout"
import ContactForm from "../components/ContactForm/ContactForm"
import MapContainer from "../components/Map/MapContainer"
import ContactHead from "../components/ContactHead/ContactHead";
import SEO from "../components/seo";


const Contact = () => {
  return (
    <Layout>
      <SEO
        title="iQubeLabs | Contact"
        description="Leave us a message and we will get back to you. We are ready to help you."
      />

      <div className="contact-grid">
        <div className="contact-form">
          <ContactHead />
          <ContactForm />
        </div>
        <div className="contact-map">
          <MapContainer 
          />
        </div>
      </div>
    </Layout>
  )
}

export default Contact

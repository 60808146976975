import React, { Component } from 'react'
import styles from './contactHead.module.scss'
import Fade from 'react-reveal/Fade';

class ContactHead extends Component {
  render() {
    return (
      <Fade left> 
        <div className={styles.contactHead}>
            <div className={styles.content}>
              <div className={styles.text}>
                <h1>
                  Need to <br></br> get in touch?
                </h1>
                <h2>
                  Leave us a message
                </h2>
              </div>
            </div>
        </div>
      </Fade>
    )
  }
}

export default ContactHead
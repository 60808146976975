import React, { Component } from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"
import styles from "./maps.module.scss"

const mapStyles = {
  position: "absolute",
  width: "100%",
  height: "100%",
}

export class MapContainer extends Component {
  render() {
    return (
      <div className={styles.mapContainer}>
        <Map
          google={this.props.google}
          style={mapStyles}
          zoom={15}
          initialCenter={{
            lat: 6.4940491,
            lng: 3.3816661
          }}
        >
          <Marker position={{ lat: 6.4940491, lng: 3.3816661 }} />
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDMKFLvnxVyKsGNKcKBqIn-kEUe56s01cc",
})(MapContainer)
